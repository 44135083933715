.newsBox {margin:30px 0;
  h2 {color:$azul-oscuro;font-family: $font-open;font-size: 22px;font-weight: 700;line-height: 1.2;margin: 0 0 30px 0;text-align:center;}
  .grid {display: flex; flex-flow: row wrap;align-items: flex-start;justify-content: space-between;
    > div {width: 100%;
      article {margin:20px 0 40px 0;
        a {text-decoration: none;
          img {display: block;height: auto;margin:0 0 20px 0;width: 100%;}
          p {color:#000000;font-family: $font-lato;font-size:16px;line-height:1.2;margin:10px 20px;
            b {color:$azul-oscuro;font-weight: 600;font-size:18px;}
          }
        }
      }
    }
  }
  @media only screen and (min-width: $break-m) {margin:60px 0;
    h2 {font-size: 38px;margin:0 0 60px 0;}
    .grid {
      > div {width: 66%;
        article {margin: 0 0 20px 0;
          a {
            img {margin:0 0 30px 0;}
            p {font-size: 18px;
              b {font-size: 22px;}
            }
          }
          a:hover {
            img {transform: scale3d(1.02,1.02,1.02)}
            p {
              b {color:$azul;}
            }
          }
        }
      }
      > div + div {width: 33%;}
      > div:last-child {display: flex; flex-flow: row wrap;align-items: flex-start;justify-content: space-between;width: 100%;
        article {width: 49%;
          a {
            img {margin:0 0 10px 0;}
            p {margin:0;}
          }
        }
      }
    }
  }
  @media only screen and (min-width: $break-l) {margin:60px 0;
    h2 {font-size: 51px;margin:0 0 60px 0;}
  }
  @media only screen and (min-width: $break-xl) {margin:60px 0;
    h2 {font-size: 38px;margin:0 0 60px 0;}
    .grid {
      > div {width: 49%;
        article {
          a {
            img {margin:0 0 40px 0;}
            p {font-size: 22px;
              b {font-size: 26px;}
            }
          }
        }
      }
      > div + div {width: 24.5%;}
      > div:last-child {display: block;width: 24.5%;
        article {width: 100%;}
      }
    }
  }
}

.noticia {margin:0 auto;max-width: $break-xl;padding:0 20px;
    h1 {color:$azul-oscuro;font-family: $font-open;font-size: 22px;font-weight: 700;line-height: 1.2;margin: 10px 0 30px 0;text-align:left;}
    > div {margin:0 0 60px;width: 100%;
        .noticiaContent {margin:0 0 20px 0;
          .banner {margin:20px 0;
            img {display: block;height:auto;margin:0 auto;width: 100%;}
          }
          //> img {float: left;height:auto;margin:0 30px 30px 0;max-width: 100%;}
          > img {display:block;height:auto;margin:20px auto;max-width: 100%;}
          p {color:#000000;font-family: $font-lato;font-size:16px;font-weight:100;line-height:1.3;margin:0 0 20px 0;
            b {font-weight: 600;}
            a {color:#000000;text-decoration: underline;}
            img {display:inline-block;height:auto;margin:10px auto;max-width: 100%;}
            .link {background-color:$azul;border: 1px solid $azul;color:#fff;display: block;padding: 10px 38px;font-size: 14px;border-radius: 20px;margin: 20px auto;position: relative;text-align: center;max-width: 240px;text-decoration: none;}
          }
          ul {margin:20px 0;
              li {color:#000000;font-family: $font-lato;font-size:16px;font-weight:100;line-height:1.3;margin:0 0 20px 0;}
          }
          
          .logosLink {display: flex; flex-flow:row wrap; justify-content: center;align-items: flex-start;
            li {margin:20px 1%;width: 31%;
              a {border:1px solid $azul;display: block;height: auto;width: 100%;
                img {display: block;height: auto;width: 100%;}
              }
              @media only screen and (min-width: $break-m) {width: 23%;}
            }
          }
        }
        .otras, .Links {margin:0 0 40px 0;
          p {color:#000000;font-family: $font-lato;font-size:16px;font-weight:600;line-height:1.3;margin:0 0 20px 0;}
          ul {
            li {font-family: $font-lato;margin:16px 0;
              a {color:#000000;text-decoration: underline;}
            }
          }
        }
        .banner {margin:0 30px 40px 30px;
          > a {display:block;margin: 0 0 30px 0;}
          img {display: block;height: auto;width: 100%;}
          .imgBanner {box-shadow: 0 0 8px #cccccc;display: block;margin:40px auto;max-width: 300px;
            img {display: block;height: auto;width: 100%;}
          }
        }
        .wrap-links {margin:30px 0;
            ul {
                li {display: inline-block;margin:10px 0;width: 360px;
                    a {@include gradient(#0a4c79,#07395a);display: flex;flex-flow: row nowrap;align-items:center;padding: 12px;border-radius: 40px;max-width: 340px;color: #fff;font-family: $font-open;text-decoration: none;font-weight: bold;text-align:center;text-shadow: 0 0 2px #000;
                        img {height: auto;width: 32px;}
                        span {font-family: $font-open;line-height:1.2;margin:0 0 0 10px;text-align:center;width: calc(100% - 40px);}
                    }
                }
            }
        }
        .wrap-links.white {margin:30px 0;
            ul {
                li {
                    a {background-color: transparent;background-image: none;border: 2px solid #888888;color: #888888;box-shadow: none;text-shadow: none;}
                }
            }
        }
    }
    @media only screen and (min-width: $break-l) {display: flex; flex-flow: row wrap;align-items: flex-start;justify-content: space-between;
        h1 {font-size: 38px;margin:20px 0 60px 0;}
        > div {margin:20px 0 60px 0;width: calc(94% - 320px);
            .noticiaContent {
                p {font-size: 18px;margin: 0 0 30px 0;
                  .link:hover {background-color:#ffffff;color:$azul;}
                }
                ul {
                    li {font-size: 18px;margin: 0 0 30px 0;}
                }
            }
        }
        > div + div {padding-top:40px;width: 300px;}
    }
    @media only screen and (min-width: $break-xl) {
        h1 {font-size: 51px;margin:0 0 60px 0;}
        > div {
            .noticiaContent {
                p {font-size: 24px;margin:0 0 40px 0;}
                ul {
                    li {font-size: 24px;margin:0 0 40px 0;}
                }
            }
            .otras, .Links {margin:0 0 60px 0;
              ul {
                li {
                  a {color:$azul;}
                }
              }
            }
            .banner {margin:0 0 60px 0;
              .imgBanner:hover {opacity:0.9;
                  img {opacity:0.9;transform: scale3d(1.02,1.02,1.02);}
              }
            }
          }
    }
}