.plantillaContenido {margin:0 auto;max-width: $break-xl;padding:0 20px;
    h1 {color:$azul-oscuro;font-family: $font-open;font-size: 22px;font-weight: 700;line-height: 1.2;margin: 10px 0 30px 0;text-align:left;}
    > div {margin:0 0 60px;width: 100%;
        .interContent {margin:0 0 20px 0;
            ul {margin:20px 0;
                li {display: inline-block;margin:4px 4px 8px 0;vertical-align: top;
                    a {background: #ffffff none;background-color: #ffffff;border: 1px solid $azul;border-radius: 30px;color: $azul-oscuro;display: block;font-family: $font-lato;font-weight: 500;font-size: 12px;min-width: 80px;padding: 8px 18px;text-align: center;text-decoration: none;}
                    a.actual {background-color: $azul;border-color:$azul;color:#ffffff;}
                }
            }
        }
        .timeline {margin:0 0 40px 0;
            .item {display: flex;flex-flow: row nowrap; align-items: flex-start;margin:0 0 30px 0;
                > div {width: 62px;
                    img {display: block;height: auto;width: 100%;}
                    p {color:#000000;font-family: $font-lato;font-size:16px;font-weight:100;line-height:1.2;margin:0 0 8px 0;
                        .dest {background-color:#c7e4fa;display: inline-block;padding:4px;}
                        b {font-weight: 600;}
                    }
                }
                > div + div {margin:0 0 0 20px;width: calc(100% - 82px);}
            }
        }
        .awards {margin:0 0 60px 0;
            .banner {
                img {display: block;height: auto;width: 100%;}
            }
            .explain {
                div {
                    dl {margin:30px 0;
                        dt {color:#000000;font-family: $font-lato;font-size:16px;font-weight:600;line-height:1.2;margin:0 0 8px 0;}
                        dd {color:#000000;font-family: $font-lato;font-size:16px;font-weight:100;line-height:1.2;margin:0 0 8px 0;}
                    }
                }
            }
        }
        .awardsLine {margin:0 0 40px 0;
            .item {display: flex;flex-flow: row nowrap; align-items: center;margin:0 0 30px 0;
                > div {width: 110px;
                    img {display: block;height: auto;width: 100%;}
                    p {color:#000000;font-family: $font-lato;font-size:16px;font-weight:100;line-height:1.2;margin:0 0 8px 0;
                        b {font-weight: 600;}
                    }
                }
                > div + div {margin:0 0 0 20px;width: calc(100% - 130px);}
            }
        }
        .imgBanner {box-shadow: 0 0 8px #cccccc;display: block;margin:40px auto;max-width: 300px;
            img {display: block;height: auto;width: 100%;}
        }
        .curriculum {
            p {color:#000000;font-family: $font-lato;font-size:16px;font-weight:100;line-height:1.2;margin:0 0 20px 0;
                b {font-weight: 600;}
            }
            ul {list-style: none;
                li {list-style: none;color:#000000;font-family: $font-lato;font-size:16px;font-weight:100;line-height:1.2;margin:0 0 20px 0;
                    b {font-weight: 600;}
                }
            }
            .videoWrap {margin:40px auto;max-width: 714px;
                a {display: block;
                    img {display: block;height: auto;width: 100%;}
                }
            }
        }
        .wrap-links {margin:30px 0;
            ul {
                li {display: inline-block;margin:10px 0;width: 360px;
                    a {@include gradient(#0a4c79,#07395a);display: flex;flex-flow: row nowrap;align-items:center;padding: 12px;border-radius: 40px;max-width: 340px;color: #fff;font-family: $font-open;text-decoration: none;font-weight: bold;text-align:center;text-shadow: 0 0 2px #000;
                        img {height: auto;width: 32px;}
                        span {font-family: $font-open;line-height:1.2;margin:0 0 0 10px;text-align:center;width: calc(100% - 40px);}
                    }
                }
            }
        }
        .wrap-links.white {margin:30px 0;
            ul {
                li {
                    a {background-color: transparent;background-image: none;border: 2px solid #888888;color: #888888;box-shadow: none;text-shadow: none;}
                }
            }
        }

        .banner {margin:0 30px 40px 30px;
            > a {display:block;margin: 0 0 30px 0;}
            img {display: block;height: auto;width: 100%;}
          .imgBanner {box-shadow: 0 0 8px #cccccc;display: block;margin:40px auto;max-width: 300px;
            img {display: block;height: auto;width: 100%;}
          }
        }

        .bannerDest {margin:0 0 40px 0;
          .imgBanner {box-shadow: 0 0 8px #cccccc;display: block;margin:30px auto;max-width: 300px;
            img {display: block;height: auto;width: 100%;}
          }
        }
    }
    @media only screen and (min-width: $break-l) {display: flex; flex-flow: row wrap;align-items: flex-start;justify-content: space-between;
        h1 {font-size: 38px;margin:20px 0 60px 0;}
        > div {margin:20px 0 60px 0;width: calc(94% - 320px);
            .interContent {
                ul {
                    li {
                        a:hover {background-color: $azul;border-color:$azul;color:#ffffff;}
                    }
                }
            }
            .timeline {
                .item {
                    > div {
                        p {font-size: 18px;}
                    }
                }
            }
            .imgBanner:hover {opacity:0.9;
                img {opacity:0.9;transform: scale3d(1.02,1.02,1.02);}
            }

            .awards {
                .explain {display: flex;flex-flow: row nowrap;justify-content: space-between;align-items: flex-start;margin-top: 40px;
                    div {width: 48%;
                        dl {margin:40px 0;
                            dt {font-size:18px;}
                            dd {font-size:18px;}
                        }
                    }
                }
            }
            .awardsLine {
                .item {
                    > div {
                        p {font-size:18px;}
                    }
                }
            }
            .curriculum {
                p {font-size:18px;margin:0 0 30px 0;}
                ul {
                    li {font-size:18px;margin:0 0 30px 0;}
                }
            }
        }
        > div + div {padding-top:40px;width: 300px;}
    }
    @media only screen and (min-width: $break-xl) {
        h1 {font-size: 51px;margin:0 0 60px 0;}
        > div {
            .timeline {
                .item {
                    > div {
                        p {font-size: 24px;}
                    }
                }
            }
            .awards {margin:40px 0 80px 0;
                .explain {
                    div {
                        dl {margin:40px 0;
                            dt {font-size:24px;}
                            dd {font-size:24px;}
                        }
                    }
                }
            }
            .awardsLine {
                .item {
                    > div {
                        p {font-size:24px;}
                    }
                }
            }
            .curriculum {
                p {font-size:24px;}
                ul {
                    li {font-size:24px;}
                }
            }
        }
    }
}