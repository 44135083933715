.bannerDoctor {display:flex; flex-flow: row nowrap;position: relative;
  > div {background-color:#eceff2;color:$azul-oscuro;padding:10px 5% 10px 10px;position:relative;text-align:left;width: 80%;
    > div {max-width: 290px;margin: 0;position:relative;z-index:2;}
    h2 {font-family: $font-lato;font-weight: 200;font-size: 16px;line-height: 1.2;
      strong {display: block;font-size:22px;font-weight: 600;}
    }
    p {margin: 10px 0;
      a {@include gradient(#2b92de,#195b8d);border-radius:30px;color:#ffffff;display: inline-block;font-family: $font-lato;font-weight:600;font-size:12px;padding:10px 20px;text-decoration:none;text-shadow: 1px 1px 2px #000000;}
    }
    ul.menu {display:inline-block;margin:20px 0 10px;
      > li {display:block;margin:8px 0;padding:4px 0;position: relative;
        a {background:transparent none;border:1px solid $azul-oscuro;border-radius:30px;color:$azul-oscuro;display: inline-block;font-family: $font-lato;font-weight:500;font-size:13px;min-width:100px;padding:10px;text-align:center;text-decoration:none;}
        a.on + ul {display: block;}
        > a.on {background-color:$azul;border-color:$azul;color:#ffffff;}
        > ul {background-color: #073859;display: none;position: absolute;top: -40px;left: 100%;z-index: 1;min-width: 180px;
          > li {margin:4px 0;padding:0;position: relative;
            a {border-radius:0;color: #ffffff;display: block;border: none;text-align: left;text-transform: uppercase;padding: 14px 16px 14px 8px;position:relative;margin: 0;}
            > a::before {background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMSIgaGVpZ2h0PSIxOSIgdmlld0JveD0iMCAwIDExIDE5Ij4gICAgPHBhdGggZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2U9IiNGRkYiIHN0cm9rZS1saW5lY2FwPSJzcXVhcmUiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iMiIgZD0iTTIuNSAyLjVsNyA3LTcgNyIvPjwvc3ZnPg==);content: '';display: block;height: 20px;margin: -10px 0 0 0;position: absolute;right: 2px;top: 50%;width: 16px;background-repeat: no-repeat;background-size: 10px 14px;background-position: center center;transform: rotate(90deg);}
            > a.on::before {transform: rotate(-90deg);}
            > ul {background-color: #05273e;display: none;position: static;top: 0;left: 100%;min-width:160px;z-index: 1;
              li {margin:4px 0;padding:0;position: relative;
                a {border-radius:0;color: #ffffff;display: block;border: none;text-align: left;text-transform: uppercase;padding: 16px;margin: 0;}
              }
            }
          }
        }
      }
      > li:first-child {
        > a {@include gradient(#2c94e1,#185a89);border:1px solid $azul-oscuro;border-radius:30px;color:$azul-oscuro;border:none;color:#ffffff;font-family: $font-lato;font-size:20px;padding:18px 30px;text-shadow: 0 0 2px #333333;}
      }
    }
  }
  > div + div {background-color:$azul-oscuro;color:#ffffff;padding:20px 20px 20px 5%;text-align:right;width: 20%;}
  > div + div::after {background:transparent url(/images/doctor_ripoll.png) no-repeat center bottom;content: '';display: block;height: 90%;left: 0;bottom: 0;position: absolute;z-index: 1;margin: 0 0 0 -120px;background-size: auto 100%;width: 290px;z-index: 1;}
  @media only screen and (min-width: $break-s) {
    > div {padding:20px 5% 20px 20px;width: 60%;
      > div {margin: 0 0 0 auto;}
      h2 {font-size: 18px;
        strong {font-size:36px;}
      }
      p {margin:20px 0;
        a {font-size:14px;padding:12px 30px;}
      }
      ul.menu {
        > li {
          a {font-size:14px;min-width: 100px;padding:10px;}
          > ul {min-width: 180px;
            li {
              a {padding:16px;}
              > a::before {content:none;}
              ul {min-width: 180px;position:absolute;}
            }
          }
        }
        li:hover {
          > ul {display: block;
            li:hover {background-color: #05273e;
              > ul {display: block;}
            }
          }
        }
      }
    }
    > div + div {width: 40%;}
    > div + div::after {left:0;margin:0 0 0 -80px;}
  }
  @media only screen and (min-width: $break-m) {
    > div {width: 50%;
      h2 {font-size: 18px;
        strong {font-size:36px;}
      }
      p {margin:20px 0;
        a {font-size:14px;padding:12px 30px;}
      }
      ul.menu {
        li {
          a {font-size:14px;min-width: 100px;padding:10px;}
          a:hover {background-color: $azul-oscuro;color:#ffffff;}
          > ul {min-width: 200px;
            li {
              ul {min-width: 200px;}
            }
          }
        }
      }
    }
    > div + div {width: 50%;}
  }
  @media only screen and (min-width: $break-l) {
    > div {
      > div {margin: 0 0 0 auto;}
      h2 {font-size: 36px;
        strong {font-size:76px;}
      }
      p {margin:20px 0;
        a {font-size:16px;padding:12px 30px;}
      }
      ul.menu {
        li {
          > ul {min-width: 240px;
            li {
              ul {min-width: 240px;}
            }
          }
        }
      }
    }
    > div + div::after {height:100%;left:0;margin:0 0 0 -90px;width: 360px;}
  }
}


.dossier {color:$azul-oscuro;font-family: $font-open;margin:30px 0;padding:0;position: relative;
  .container {position: relative;z-index: 2;
    .grid {display: flex;flex-flow: row wrap;align-items: center;
      div {padding:10px 10% 10px 0;width: 100%;
        img {display: block;height: auto;max-width: 100%;}
        p {display: inline-block;font-size: 22px;line-height: 1.2;margin: 20px 0;width: 51%;vertical-align: middle;
          b {font-weight: bold;}
        }
        ul {display: inline-block;width: 47%;vertical-align: middle;
          li {margin:10px 0;
            a {background-color:$azul;border:1px solid $azul;color:#fff;display: block;padding: 10px 38px;font-size: 14px;border-radius: 20px;margin:0 0 0 auto;position: relative;text-align: center;max-width: 240px;
              span {display: inline-block;font-family: $font-lato;font-weight:bold;}
              .eng {display: block;height: 26px;width: 26px;position: absolute;left: 4px;top: 4px;background:transparent url(/images/eng-icon.png) no-repeat center center;border-radius: 50%;background-size: cover;}
            }
          }
          li + li a {background-color: transparent;border: 1px solid #000000;color:$azul-oscuro;}
        }
      }
      div + div {padding:10px 20px;}
      @media only screen and (min-width: $break-s) {
        div {
          p {font-size: 30px;}
          ul {
            li {
              a {font-size: 18px;}
            }
          }
        }
      }
      @media only screen and (min-width: $break-m) {
        div {width: 50%;
          p {font-size: 24px;}
          ul {
            li {
              a {font-size: 16px;}
              a:hover {background-color: #ffffff;color:$azul;}
            }
            li + li a:hover {background-color: $azul-oscuro;color:#ffffff;}
          }
        }
      }
      @media only screen and (min-width: $break-l) {
        div {
          p {font-size: 32px;}
        }
        div + div {padding:10px 10% 10px 0;}
      }
      @media only screen and (min-width: $break-xl) {
        div {
          p {font-size: 50px;}
          ul {
            li {margin:20px 0;
              a {font-size: 19px;
                .eng {height: 30px;width: 30px;}
              }
            }
          }
        }
      }
    }
  }
}
.dossier::after {background-color:#eceff2;content:'';display: block;height: 90%;left:0;top:10%;position:absolute;width: 100%;z-index:1;
  @media only screen and (min-width: $break-m) {height: 70%;top:15%;}
}

.instalaciones {color:#ffffff;font-family: $font-open;margin:30px 0;padding:0;position: relative;
  .container {position: relative;z-index: 2;
    .grid {display: flex;flex-flow: row wrap;align-items: center;
      div {padding:10px 10%;position:relative;width: 100%;
        img {display: block;height: auto;width: 100%;}
        h3 {display: block;font-family: $font-open;font-size: 22px;font-weight:bold;line-height: 1.2;margin: 20px 0 0 0;}
        p {display: block;font-family: $font-open;font-size: 22px;font-weight:300;line-height: 1.2;margin: 0 0 20px 0;}
        .link {background-color:#ffffff;color:$azul;display: block;font-family: $font-open;font-weight: 600;padding: 10px 38px;font-size: 14px;border-radius: 20px;margin:0;position: relative;text-align: center;max-width: 200px;}
        .img {display: block;}
      }
      div + div {padding:10px 0;}

      @media only screen and (min-width: $break-m) {
        div {width: 50%;
          .link {position: absolute;left: 100%;top: 50%;z-index: 1;width: 200px;margin: -15px 0 0 -100px;}
        }
      }
      @media only screen and (min-width: $break-l) {
        div {
          h3 {font-size: 32px;}
          p {font-size: 32px;}
          .link {font-size: 16px;}
          .link:hover {background-color: $azul;color:#ffffff;}
          .img:hover {
            img {transform: scale3d(1.1,1.1,1.1);}
          }
        }
        div + div {padding:10px 5% 10px 0;}
      }
      @media only screen and (min-width: $break-xl) {margin:30px 0 60px 0;
        div {
          h3 {font-size: 50px;}
          p {font-size: 50px;}
          .link {font-size: 19px;}
        }
        div + div {padding:10px 5% 10px 0;}
      }
    }
  }
}
.instalaciones::after {background-color:$azul-oscuro;content:'';display: block;height: 90%;left:0;top:0;position:absolute;width: 100%;z-index:1;
  @media only screen and (min-width: $break-m) {height: 70%;top:15%;}
}

.specialityBox {display: flex; flex-flow: row wrap;justify-content: space-between;
  > div {background-color: #eceff2;position: relative;width: 100%;
    > a {display: block;text-decoration: none;}
    .content {padding:30px 30px 120px 30px;position: relative;z-index: 2;
      p {color:$azul-oscuro;font-family: $font-open;font-size: 22px;font-weight:300;line-height: 1.2;margin: 0 0 10px 0;
         b {font-weight: 600;}
         a {color:#ffffff;font-weight: 600;text-decoration: none;}
      }
    }
    .bg {overflow:hidden;position: absolute;right: 0;top: 0;height: 100%;width: 60%;
      svg {fill:#c6c6c6;display: block;margin: -10% -10% 0 0;width: 150%;height: 150%;}
    }
  }
  > div + div {background-color: $azul-oscuro;
    .content {
      p {color:#ffffff;}
    }
    .bg {
      svg {fill: #136092;/*transform: scaleX(-1);position: absolute;bottom: 0;right: -20%;*/}
    }
  }
  @media only screen and (min-width: $break-m) {
    > div {width: 49%;
      > a:hover {
        .content {
          p {color:$azul;}
        }
        .bg {
          svg {height: 140%;width: 140%;}
        }
      }
      .content {
        p {
          a:hover {color:$azul;}
        }
      }
    }
  }
  @media only screen and (min-width: $break-l) {
    > div {
      .content {padding:80px 40px;
        p {font-size: 28px;}
      }

    }
  }
  @media only screen and (min-width: $break-xl) {
    > div {
      .content {padding:100px 40px;
        p {font-size: 42px;}
      }

    }
  }
}

.go5dBox {background-color: #eceff2;margin:40px 0;position: relative;
  > div {padding:20px;position: relative;
    img {display: block;height: auto;margin:0 auto;max-width: 340px;width: 90%;}
    p {color:#000000;font-family: $font-rubik;font-size: 22px;font-weight:300;line-height: 1.2;margin: 10px 0;
      b {font-weight: 700;}
      a {background-color: transparent; border: 1px solid #e93e35;border-radius:30px;color:#000000;display:block;font-family: $font-rubik;font-size:18px;font-weight:400;padding:10px;width: 200px;text-align: center;text-decoration: none;}
    }
  }
  > div + div::before {background-color:#e93e35;content:'';width: 90%;height: 1px;position: absolute;left:5%;top:0px;}
  @media only screen and (min-width: $break-m) {display: flex; flex-flow: row wrap;align-items: center;justify-content: center;margin:60px 0;padding:0 26%;
    > div {width: 49%;
      p {
        a:hover {background-color: #e93e35;color:#ffffff;}
      }
    }
    > div + div::before {height: 75%;left:0;top:15%;width: 1px;}
  } 
  @media only screen and (min-width: $break-l) {
    > div {
      p {font-size: 28px;}
    }
  } 
  @media only screen and (min-width: $break-xl) {
    > div {
      p {font-size: 37px;}
    }
  } 
}
.go5dBox::before {
  @media only screen and (min-width: $break-m) {background: transparent url('/images/go5d_izquierda.png') no-repeat right center;background-size: auto 100%;content:'';display: block;height: 100%;left:0;position:absolute;top:0;width: 30%;}
}
.go5dBox::after {
  @media only screen and (min-width: $break-m) {background: transparent url('/images/go5d_derecha.png') no-repeat left center;background-size: auto 100%;content:'';display: block;height: 100%;position:absolute;right:0;top:0;width: 30%;}
}

.mapBox {background-color: #eceff2;margin:40px 0;padding:30px 30%;position: relative;
  > p {font-family: $font-lato;font-size: 16px;font-weight:600;margin:20px 0;width: 100%;text-align: center;}
  > div {padding:0 20px;position: relative;
    img {display: block;height: auto;margin:0 auto;width: 100%;}
  }
  @media only screen and (min-width: $break-m) {display: flex; flex-flow: row wrap;align-items: center;justify-content: center;margin:40px 0;padding:20px 30% 60px 30%;
    > div {width: 49%;}
    > p {font-size: 20px;margin:40px 0 20px 0;}
  } 
  @media only screen and (min-width: $break-l) {
    > p {font-size: 25px;margin:60px 0 20px 0;}
  } 
}
.mapBox::before {background: transparent url('/images/mapa_izquierda.png') no-repeat right center;background-size: auto 100%;content:'';display: block;height: 100%;left:0;position:absolute;top:0;width: 30%;
  @media only screen and (min-width: $break-l) {background-position: left center;}
}

.mapBox::after {background: transparent url('/images/mapa_derecha.png') no-repeat left center;background-size: auto 100%;content:'';display: block;height: 100%;position:absolute;right:0;top:0;width: 30%;
  @media only screen and (min-width: $break-l) {background-position: right center;}
}


.cuadroBox {margin:30px 0;padding:0 20px;
  h2 {color:$azul-oscuro;font-family: $font-open;font-size: 22px;font-weight: 700;line-height: 1.2;margin: 0 0 30px 0;text-align:center;}
  .grid {
    a {color:#000000;text-decoration: none;
      img {display: block;height: auto;margin:0 0 10px 0;width: 100%;}
      p {color:#000000;font-family: $font-lato;font-weight: 200;font-size: 16px;line-height: 1.2;margin:4px 10px;
        strong {color:$azul-oscuro;display: block;font-style:normal;font-weight: 600;}
        em {color:$azul;display: block;font-style:normal;font-weight: 400;}
      }
    }
  }
  @media only screen and (min-width: $break-m) {margin:60px 0;padding:0;
    h2 {font-size: 38px;margin:0 0 60px 0;}
    a:hover {
      img {opacity:0.9;transform: scale3d(1.02,1.02,1.02);}
    }
  }
  @media only screen and (min-width: $break-l) {margin:60px 0;
    h2 {font-size: 46px;margin:0 0 60px 0;}
    .grid {display: flex; flex-flow: row wrap; align-items:flex-start; justify-content: space-around;
      > div {margin:20px 4%;width:15%;
        p {font-size: 20px;margin:4px 0;}
      }
      > div:nth-child(1), > div:nth-child(2), > div:nth-child(3), > div:nth-child(4) {margin:30px 2%;width:20%;}
    }
    .swiper-button-next, .swiper-button-prev {display: none;}
  }
  @media only screen and (min-width: $break-xl) {margin:60px 0;
    h2 {font-size: 51px;}
    .grid {
      > div {
        p {font-size: 24px;}
      }
    }
  }
}

.question {margin:40px 0;
  h2 {color:$azul-oscuro;font-family: $font-open;font-size: 22px;font-weight: 700;line-height: 1.2;margin: 0 0 30px 0;text-align:center;}
  div {
    ul {display: flex;flex-flow: row wrap;justify-content: center;
      li {margin:20px 0;width: 100%;
        a {display: block;font-family: $font-open;margin:0 auto;text-decoration: none;width:204px;
          em {color:$azul-oscuro;font-weight: 600;font-size: 18px;font-style:normal;display: block;text-align: center;margin:0 0 10px 0;}
          span {background: #094b77; background: -moz-radial-gradient(center, ellipse cover, #094b77 0%, #07395a 100%);background: -webkit-radial-gradient(center, ellipse cover, #094b77 0%,#07395a 100%); background: radial-gradient(ellipse at center, #094b77 0%,#07395a 100%);display: block;border:6px solid #ffffff;box-shadow: 2px 2px 4px #666666;border-radius:50%;padding:26px;
            svg {fill:#fff;display: block;height: 140px;width: 140px;}
          }
        }
      }
    }
  }
  @media only screen and (min-width: $break-m) {
    h2 {font-size: 38px;margin:0 0 60px 0;}
    div {
      ul {justify-content: space-between;
        li {margin:20px 0;width: 50%;}
      }
    }
  }
  @media only screen and (min-width: $break-l) {margin:60px 0;
    h2 {font-size: 51px;margin:0 0 60px 0;}
    div {
      ul {position: relative;
        li {margin:20px 0;position:relative;width: 25%;z-index: 2;}
      }
      ul::before {background-color:#eceff2;content: '';display: block;height: 50%;left: 0;top: 25%;position: absolute;width: 100%;z-index: 1;}
    }
  }
}

.bannerMinDoctor {background: $azul-oscuro url('/images/bg_header_seccion.jpg') no-repeat center top;background-size:cover;margin:0 0 20px 0;padding:0;
  > div {margin:0 auto;max-width: $break-xxl;
    p {color:#ffffff;display: block;font-family: $font-lato;max-width: $break-l;margin: 0 auto;padding: 40px 0 40px 180px;position: relative;font-size: 20px;
      b {display: block;font-weight: 600;font-size: 44px;}
    }
    p::before {content: '';display: block;height: 100%;width: 164px;background:transparent url('/images/torso_ripoll.png') no-repeat center bottom;position: absolute;left: 0;top: 0;background-size: cover;}
  }
  @media only screen and (min-width: $break-m) {
    > div {
      p {padding: 50px 0 50px 270px;font-size: 33px;line-height: 1.2;
        b {font-size: 71px;}
      }
      p::before {width: 260px;}
    }
  }
}

.linkBanner {margin: 40px 0;
  .item {margin:30px 0;overflow:hidden;padding:1px 0;position: relative;
    .content {position: relative;z-index: 2;max-width: 90%;padding: 1px 20px;color:#fff;
      p {display: block;font-family: $font-open;font-size: 22px;line-height: 1.2;margin: 20px 0;
        b {font-weight: 700;}
        a {background-color: transparent;border: 1px solid #ffffff;color: #fff;display: block;padding: 10px 38px;font-size: 14px;border-radius: 20px;margin: 0;position: relative;text-align: center;text-decoration:none;max-width: 240px;}
      }
    }
    .content.colorBlue {color:$azul-oscuro;
      p {
        a {border-color:$azul-oscuro;color:$azul-oscuro;}
      }
    }
    .bg {height:100%;position: absolute;right:0;top:0;width: 100%;
      img {display: block;height: 100%;object-fit: cover;object-position: top left;width: 100%;}
    }
  }
  @media only screen and (min-width: $break-m) {margin:60px 0;
    .item {
      .content {max-width: 50%;
        p {
          a:hover {border-color:$azul;}
        }
      }
    }
  }
  @media only screen and (min-width: $break-l) {
    .item {margin:30px 0;
    .content {padding: 1px 40px;max-width: 42%;
        p {font-size: 32px;margin:30px 0;
          a {font-size: 18px;}
        }
      }
    }
    .content.w55 {max-width: 55%;}
    .content.w65 {max-width: 65%;}
  }
  @media only screen and (min-width: $break-xl) {
    .item {
    .content {
        p {font-size: 34px;margin:40px 0;}
      }
    }
  }
}