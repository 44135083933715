
header {background-color: #ffffff;color:$azul-oscuro;font-family:$font-lato;font-size:14px;line-height:1.2;padding:20px;
    .container {max-width: $break-xl;margin:0 auto;
        .headerGrid {display: flex; flex-flow: row wrap;justify-content: center;align-items: center;
            .btnToggleMenumvl {}
            div {width: 100%;
                .logo {display: block;margin: 0 auto;max-width: 320px;text-decoration: none;
                    img {display: block;height: auto;width:100%;}
                }
                .cta-tel {background-color:$azul;color:#ffffff;display:block;font-size:16px;padding:10px;position: fixed;left: 0;bottom:0;text-align:center;text-decoration:none;width: 100%;z-index: 9;
                    i {display: inline-block;vertical-align: middle;height: 20px;width: 20px;
                        svg {display: block;fill:#ffffff;height: 20px;width: 20px;}
                        @media only screen and (min-width: $break-m) {height: 30px;width: 30px;
                            svg {fill: $azul;height: 30px;width: 30px;}
                        }
                    }
                    span {display: inline-block;vertical-align: middle;max-width: calc(100% - 30px);}
                }
            }
            @media only screen and (min-width: $break-m) {display: flex;flex-flow:row wrap;justify-content: left;align-items: center;
                .btnToggleMenumvl {display:none;}
                div {width:50%;
                    .logo {margin:0;max-width: 530px;}
                    .cta-tel {background-color:#ffffff;display:inline-block;margin:0 0 0 auto;position: static;width: auto;
                        i {height: 30px;width: 30px;
                            svg {fill: $azul;height: 30px;width: 30px;}
                        }
                        span {color:#000000;font-size: 30px;}
                    }
                }
                div + div {text-align: right;}
            }
        }
    }
}

footer {background: $azul-oscuro;color:#ffffff;font-family:$font-open;font-size:14px;line-height:1.2;padding:20px;
    .container {max-width: $break-xl;margin:0 auto;
        .grid-footer {display: flex; flex-flow: row wrap;justify-content: left;align-items: center;
            div {margin:10px 0;width: 100%;
                .logo {display: block;margin: 0 auto;max-width: 340px;
                    img {display: block;height: auto;width:100%;}
                }
                p {text-align: center;}
                ul {display: flex;flex-flow: row wrap; justify-content: center;align-items: center;
                    li {margin:10px;
                        a {color:#ffffff;display: block;padding: 4px;text-decoration: underline;}
                    }
                }
            }
            @media only screen and (min-width: $break-l) {justify-content: center;
                div {margin:0;width:50%;
                    .logo {margin:0;}
                    p {text-align: left;}
                    ul {justify-content: right;}
                }
            }
        }
        hr {background-color:#ffffff;display:block;height:1px;margin:10px 0;padding:0;width: 100%;}
    }
}