.cover-modal {display: none;
    > div {display: flex;flex-flow: row wrap;justify-content: center;align-content: center;align-items: center;position: fixed;top: 0;left: 0;height: 100%;width: 100%;z-index: 99999999;
        .bg-modal {background-color:rgba(0,0,0,0.63);border-radius: 0;position: fixed;left: 0;top: 0;height: 100%;width: 100%;z-index: 999;-webkit-transition: all 0.2s ease-in-out;-moz-transition: all 0.2s ease-in-out;-o-transition: all 0.2s ease-in-out;transition: all 0.2s ease-in-out;}
        .wrap-modal {max-width: 100%;max-height: 100%;background-color: #ffffff;border-radius: 0;-webkit-transition: all 0.2s ease-in-out;-moz-transition: all 0.2s ease-in-out;-o-transition: all 0.2s ease-in-out;transition: all 0.2s ease-in-out;width: 820px;padding: 0;position: relative;z-index: 999;display: flex;flex-flow: column nowrap;overflow: hidden;
            .modal-contenido {clear: both;background-color:transparent;max-height: 100%;overflow: auto;padding: 30px 0 10px 0;position: relative;
                .video {background-color: #ffffff;padding:20px;
                    iframe {width: 100%;}
                    video {width: 100%;height: auto;box-shadow: 0 0 5px #666;}
                }
            }
            .close-modal {background-color:transparent;cursor:pointer;height: 30px;width: 30px;display: block;text-align: center;font-size: 20px;line-height: 20px;border-radius: 50%;padding: 0;position: absolute;right: 6px;top: 10px;z-index: 2;
                svg {fill: #000;height: 30px;width: 30px;}
            }
        } 
    }
}
.cover-modal.on {display: block;}