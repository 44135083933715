@import 'reset';
@import 'mixin';
//comunes
$break-xs: 321px;
$break-s: 560px;
$break-m: 768px;
$break-l: 1100px;
$break-xl: 1520px;
$break-xxl: 1800px;

$font-open: 'Open Sans', sans-serif;
$font-lato: 'Lato', sans-serif;
$font-rubik: 'Rubik', sans-serif;

$azul: #2980c0;
$azul-oscuro: #1b3855;
$azul-oscuro-2: #073859;


*, ::before, ::after {-webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box;}
a, button, img, p, svg {@include transition(all 0.4s ease);}
//comunes
.injected-svg {display: none;}
.over {overflow: hidden;}

//headerFooter
@import 'header';

//componentes
@import 'box';

//plantilla
@import 'plantilla';

//noticias
@import 'noticias';

//modales
@import 'modales';

//plugins
@import 'swiper';

.goBack {margin: 0 auto;max-width: 1520px;padding: 0 20px;
    a {background-color: $azul-oscuro;border: 1px solid $azul-oscuro;color: #fff;display: inline-block;font-family:$font-lato;padding: 10px 20px;font-size: 14px;border-radius: 20px;margin: 20px auto;position: relative;text-align: center;max-width: 240px;text-decoration: none;}
    @media only screen and (min-width: $break-m) {
        a:hover {background-color: #ffffff;color:$azul-oscuro;}
    }
}

.cookiedisclaimer {background-color: rgba(238,238,238,0.97);font-family:$font-rubik;position: fixed;bottom: 0;z-index: 99;left: 0;width: 100%;padding:10px;
    .cookie-row {display: inline-block;vertical-align:top;width: 74%;
        div {
            h5 {color:#000000;font-weight:700;font-size:15px;line-height:20px;margin:10px 0;}
            p {box-sizing: border-box;color: #262626;cursor: default;direction: ltr;display: block;font-size: 15px;font-style: normal;font-weight: 400;height: auto;line-height: 20px;margin: 10px 0;
                a.arrow {background: transparent none;border: none;color: #666666;display: block;font-size:14px;font-weight: 600;min-height: 0;text-decoration: none;padding: 0 0 0 18px;position: relative;margin: 10px 0;}
                a.arrow::before {background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2240%22%20height%3D%2240%22%20viewBox%3D%220%200%2040%2040%22%3E%3Cpath%20fill%3D%22%23666666%22%20d%3D%22M17%2028l-2-2%206-6-6-6%202-2%208%208z%22%2F%3E%3C%2Fsvg%3E");background-repeat: no-repeat;background-position: -8px -4px;background-size: 30px;left: 0;content: '';position: absolute;width: 12px;height: 18px;transition: all .25s ease,background-image 0;top: 1px;}
                a:hover {}
            }
        }
        @media only screen and (max-width:$break-s) {display: block;width: auto;}
    }
    .cookie-row + .cookie-row {vertical-align:top;width: 24%;
        a {background:$azul;color:#ffffff;display: block;margin:10px;padding:16px 20px;text-align:center;text-decoration: none;}
        @media only screen and (max-width:$break-s) {display: block;width: auto;}
    }
    @media only screen and (max-width: $break-l) {box-shadow: 0 0 2px #333333;left:0;width: 100%;z-index: 999;}
}
